import * as React from 'react';


export const Checkbox = (
    {
        name,
        defaultValue = false,
        value,
        onChange,
        children,
        ...args
    }: {
        name?: string;
        defaultValue?: boolean;
        value?: boolean;
        onChange?: React.ChangeEventHandler<HTMLInputElement>;
    } & Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'defaultValue'>
) => {
    const [checked, setChecked] = React.useState(defaultValue);

    React.useEffect(() => {
        setChecked(value);
    }, [value]);

    return <label>
        <input type="checkbox" name={name} {...args} onChange={event => setChecked(event.target.checked)} value="true" checked={checked}/>
        {children}
        {/*<input type="hidden" name={name} value={checked + ''}/>*/}
    </label>
}
