import {Route, Switch, useRouteMatch} from "react-router-dom";
import * as React from "react";
import {ListCategories} from "./ListCategories";


export const Categories =  () => {
    let { path, url } = useRouteMatch();
    return (<>
        <Switch>
            <Route path={`${path}`} exact={true}>
                <ListCategories/>
            </Route>
        </Switch>
    </>);
}
