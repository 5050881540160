import * as React from 'react';


export const Loading = (
    {
        loading,
        className = '',
        render
    }: {
        loading: any,
        render?: (value) => any,
        className?: string;
    }
) => {
    return (
        !loading ? <div className={`spinner-grow text-primary m-auto ` + className}
                       style={{width: '2rem', height: '2rem', verticalAlign: 'middle'}}/> : <>{render(loading)}</>);
}
