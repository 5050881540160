import * as React from 'react';
import {Loading} from "../helpers/Loading";
import {FetchApi} from "../../FetchApi";
import {requestHelper} from "../helpers/requestHelper";
import {FormHelper, ReactiveForm} from "../helpers/ReactiveForm";
import {Modal} from 'bootstrap';
import {Pagination} from "../helpers/Pagination";
import {useQuery} from "../helpers/useQuery";
import {setDebounce} from "../helpers/setDebounce";
import {FatwaModel, FatwaPage} from "./interfaces";
import {Link, useHistory} from 'react-router-dom';
import {DateTime} from "luxon";
import {CategoryPage} from "../categories/interfaces";


export const ListFatwas = () => {

    const [fatwas, setFatwas] = React.useState<FatwaPage>(null);
    const [query, setQuery] = useQuery();
    const history = useHistory();

    const retrieveFatwas = (body = {}) => {
        setDebounce(() => {
            FetchApi.get<FatwaPage>({
                path: 'fatwas',
                body: {sorts: '-created_at', include: 'preacher,category', ...body},
            }).then(response => {
                setFatwas(response);
            })
        }, 'fatwas', 500);
    }

    const toggleHandler = (fatwa: FatwaModel) => {
        requestHelper({
            request: FetchApi.patch({
                path: `fatwas/${fatwa.id}`,
                headers: {
                  'Content-Type': 'application/json'
                },
                body: ({
                    is_disabled: !fatwa.is_disabled,
                }) as any
            }).then(response => {
                retrieveFatwas(query);
                return response;
            }),
        })
    }

    React.useEffect(() => {
        retrieveFatwas(query);
    }, [query]);

    const filterChange = (event: FormHelper) => {
        setQuery(event.value, 'merge');
    }

    return <>
        <Link className="btn btn-primary btn-lg btn-floating" to="/fatwas/add">
            <i className="fas fa-plus fa-fw"/>
        </Link>
        <div className="d-flex p-2 p-md-4" style={{minHeight: '100vh'}}>
            <div className="my-auto w-100">
                <div className="d-flex">
                    <h2>الفتاوى</h2>
                </div>
                <div className="row">
                    <div className="col-12">
                        <ReactiveForm valueAs="json" onChange={filterChange}>
                            <input type="hidden" name="page" value={1}/>
                            <div className="row">
                                <div className="col-md-4 col-sm-6 col-xl-3 my-3">
                                    <div className="form-floating">
                                        <input type="text" id="q" name="q" className="form-control" placeholder=""
                                               defaultValue={query['q']}/>
                                        <label htmlFor="q">بحث</label>
                                    </div>
                                </div>
                            </div>
                        </ReactiveForm>
                        <hr/>
                    </div>
                    <Loading loading={fatwas} render={() =>
                        <>
                            <div className="col-12 text-center">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table table-hover">
                                                <thead>
                                                <tr>
                                                    <th className="border-primary border-top-0 border-start-0 border-end-0 border-2"
                                                        scope="col">#
                                                    </th>
                                                    <th className="border-primary border-top-0 border-start-0 border-end-0 border-2"
                                                        scope="col">عنوان الفتوى
                                                    </th>
                                                    <th className="border-primary border-top-0 border-start-0 border-end-0 border-2"
                                                        scope="col">اسم المفتي
                                                    </th>
                                                    <th className="border-primary border-top-0 border-start-0 border-end-0 border-2"
                                                        scope="col">التصنيف
                                                    </th>
                                                    <th className="border-primary border-top-0 border-start-0 border-end-0 border-2"
                                                        scope="col">وقت الانشاء
                                                    </th>
                                                    <th className="border-primary border-top-0 border-start-0 border-end-0 border-2"
                                                        scope="col">اخر تعديل
                                                    </th>
                                                    <th className="border-primary border-top-0 border-start-0 border-end-0 border-2"
                                                        scope="col">الحالة
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {fatwas.data.map((fatwa, index) =>
                                                    <tr key={fatwa.id} onClick={() => history.push(`/fatwas/${fatwa.id}`)}>
                                                        <td className="border-primary py-3">{index + (fatwas.meta.per_page * fatwas.meta.current_page)}</td>
                                                        <td className="border-primary py-3">{fatwa.title}</td>
                                                        <td className="border-primary py-3">{fatwa.preacher?.name}</td>
                                                        <td className="border-primary py-3">{fatwa.category?.name}</td>
                                                        <td className="border-primary py-3">{DateTime.fromISO(fatwa.created_at).toLocaleString(DateTime.DATETIME_MED)}</td>
                                                        <td className="border-primary py-3">{DateTime.fromISO(fatwa.updated_at).toLocaleString(DateTime.DATETIME_MED)}</td>
                                                        <td className="border-primary py-3">
                                                            <input type="checkbox" className="slidebox inverted"
                                                                   readOnly={true}
                                                                   checked={fatwa.is_disabled}
                                                                   onClickCapture={event => {
                                                                       event.nativeEvent.stopImmediatePropagation();
                                                                       toggleHandler(fatwa);
                                                                   }}/>
                                                        </td>
                                                    </tr>
                                                )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 my-3">
                                <Pagination pagination={fatwas.meta}/>
                            </div>
                        </>
                    }/>
                </div>
            </div>
        </div>
    </>
}
