import * as React from 'react';
import {Route, Switch, useRouteMatch} from 'react-router-dom';
import {ListPreachers} from "./ListPreachers";

export const Preachers = () => {
    let { path, url } = useRouteMatch();
    return (<>
        <Switch>
            <Route path={`${path}`} exact={true}>
                <ListPreachers/>
            </Route>
        </Switch>
    </>);
}
