import * as React from 'react';


export const useQuery = () => {


    const getQueryObject = () => {
        const search = location.search;
        if (search.length) {
            return (JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) {
                return key === "" ? value : decodeURIComponent(value)
            }));
        } else {
            return ({});
        }
    }


    React.useEffect(() => {
        if (window.history.pushState) {
            const pushState = history.pushState;
            history.pushState = function (state) {
                // @ts-ignore
                if (typeof history.onpushstate == "function") {
                    // @ts-ignore
                    history.onpushstate({state: state});
                }
                const value = pushState.apply(history, arguments);
                setQuery(getQueryObject());
                return value;
            }
        }
    }, []);

    const [query, setQuery] = React.useState(getQueryObject());

    const setQueryParam = (query: object, handle?: 'merge') => {
        let searchParams = new URLSearchParams(window.location.search);
        if (handle !== 'merge') {
            // @ts-ignore
            for (const key of searchParams) {
                searchParams.delete(key[0]);
            }
        }

        for (const key of Object.keys(query)) {
            searchParams.set(key, query[key]);
        }

        let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
        setQuery(Object.entries(searchParams).reduce((acc, [key, value]) => {
            acc[key.replace(/^\?/,'')] = value;
            return acc;
        }, {}));
        window.history.pushState({path: newurl}, '', newurl);
    }

    return [query, setQueryParam];
}
