import * as React from "react";
import {SettingModel} from "./interfaces";
import {FormHelper, ReactiveForm} from "../helpers/ReactiveForm";
import {useEffect, useRef} from "react";


const ResponsiveTextArea = (props: React.HTMLProps<HTMLTextAreaElement>) => {
    const ref = useRef<HTMLTextAreaElement>();

    const updateHeight = () => {
        ref.current?.style.setProperty('overflow', 'hidden')
        ref.current?.style.setProperty('height', 'auto')
        ref.current?.style.setProperty('height', `${ref.current?.scrollHeight}px`)
    }

    useEffect(() => {
        updateHeight()
    }, [])

    return <textarea
        {...props}
        ref={r => ref.current = r}
        onChange={e => updateHeight()}/>
}

export const SettingCard =
    ({
         setting,
         updateSetting,
     }: {
        setting: SettingModel,
        updateSetting: (setting: SettingModel, event: FormHelper) => any
    }) => {

        return <>
            <div className="col-12 my-3">
                <ReactiveForm valueAs={"json"} onSubmit={e => updateSetting(setting, e)}>
                    <div className="form-floating">
                        {
                            setting.field_type === 'textarea' ?
                                <ResponsiveTextArea
                                    className="form-control" name="value"
                                    defaultValue={setting.value}
                                />
                                : <input type={setting.field_type} className="form-control" name="value"
                                         defaultValue={setting.value}/>
                        }
                        <label>{setting.key}</label>
                    </div>
                    <div className="d-flex justify-content-end">
                        <button className="btn">تحديث</button>
                    </div>
                </ReactiveForm>
            </div>
        </>
    }
