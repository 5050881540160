import * as React from 'react';
import {FatwaItem} from "./interfaces";
import {PreacherPage} from "../preachers/interfaces";
import {setDebounce} from "../helpers/setDebounce";
import {FetchApi} from "../../FetchApi";
import {useHistory, useParams} from "react-router-dom";
import {FormHelper, ReactiveForm} from "../helpers/ReactiveForm";
import {requestHelper} from "../helpers/requestHelper";
import {Loading} from "../helpers/Loading";
import {BsEditor} from "../helpers/BsEditor";
import {Checkbox} from "../helpers/Checkbox";
import {useCategories} from "../../hooks/useCategories";


export const CreateFatwa = () => {
    const [preachers, setPreachers] = React.useState<PreacherPage>(null);
    const {categories} = useCategories()
    const history = useHistory();

    const retrievePreachers = (body = {}) => {
        setDebounce(() => {
            FetchApi.get<PreacherPage>({
                path: 'preachers',
                body: {sorts: '-created_at', ...body},
            }).then(response => {
                setPreachers(response);
            })
        }, 'preachers', 500);
    }

    React.useEffect(() => {
        retrievePreachers({paginate: 1000000000000});
    }, []);

    function createFatwa(event: FormHelper) {
        requestHelper({
            form: event,
            request: FetchApi.post<FatwaItem>({
                path: `fatwas`,
                body: event.value,
            }).then(m => {
                history.push(`/fatwas/${m.id}`);
                return m;
            }),
        });
    }


    return <>
        <div className="d-flex p-2 p-md-4" style={{minHeight: '100vh'}}>
            <div className="my-auto w-100">
                <div className="d-flex">
                    <h2>اضافة فتوى</h2>
                </div>
                <ReactiveForm onSubmit={createFatwa} valueAs={"json"} className="card my-3">
                    <button className="btn btn-primary btn-lg btn-floating">
                        <i className="fas fa-save fa-fw"/>
                    </button>
                    <div className="card-body">
                        <div className="row g-3">
                            <div className="col-12">
                                <div className="form-floating">
                                    <input type="text" className="form-control" name="title" placeholder=""
                                    />
                                    <label>عنوان الفتوى</label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <Loading loading={categories} render={() =>
                                    <>
                                        <div className="form-floating">
                                            <select
                                                className="form-control"
                                                name="category_id">
                                                {categories.data.map(category => <option key={category.id}
                                                                                         value={category.id}>{category.name}</option>)}

                                            </select>
                                            <label>تصنيف</label>
                                        </div>
                                    </>
                                }/>
                            </div>
                            <div className="col-md-6">
                                <Loading loading={preachers} render={() =>
                                    <>
                                        <div className="form-floating">
                                            <select className="form-select" name="preacher_id" placeholder="">
                                                {preachers.data.map(preacher => <option key={preacher.id} value={preacher.id}>{preacher.name}</option>)}
                                            </select>
                                            <label>المفتي</label>
                                        </div>
                                    </>
                                }/>
                            </div>
                        </div>
                        <div className="col-12 my-3">
                            <div className="form-floating">
                                <BsEditor name="body"/>
                                <label>المحتوى</label>
                            </div>
                        </div>
                        <div className="col-12 my-3">
                            <div className={"form-group p-3"}>
                                <Checkbox type="checkbox" name="is_featured" defaultValue={false} className="slidebox">
                                    فتوى مميزة
                                </Checkbox>
                            </div>
                        </div>
                    </div>
                </ReactiveForm>

            </div>
        </div>
    </>
}
