import * as React from 'react';
import {Loading} from "../helpers/Loading";
import {FetchApi} from "../../FetchApi";
import {requestHelper} from "../helpers/requestHelper";
import {FormHelper, ReactiveForm} from "../helpers/ReactiveForm";
import {Modal} from 'bootstrap';
import {Pagination} from "../helpers/Pagination";
import {useQuery} from "../helpers/useQuery";
import {setDebounce} from "../helpers/setDebounce";
import {CategoryModel, CategoryPage} from "./interfaces";

export const ListCategories = () => {

    const [categories, setCategories] = React.useState<CategoryPage>(null);
    const [category, setCategory] = React.useState<CategoryModel>(null);
    const [query, setQuery] = useQuery();

    const retrieveCategories = (body = {}) => {
        FetchApi.get<CategoryPage>({
            path: 'categories',
            body: {sorts: '-created_at', filters: 'parent_id:1', ...body},
        }).then(response => {
            setCategories(response);
        });
    }

    React.useEffect(() => {
        retrieveCategories(query);

    }, [query]);

    const toggleHandler = (category: CategoryModel) => {
        requestHelper({
            request: FetchApi.patch({
                path: `categories/${category.id}`,
                // @ts-ignore
                body: {is_disabled: !category.is_disabled}
            }).then(response => {
                retrieveCategories(query);
                return response;
            }),
        })
    }


    function addCategory(event: FormHelper) {
        requestHelper({
            request: FetchApi.post({
                path: `categories`,
                body: event.value,
            }).then(m => {
                retrieveCategories(query)
                return m;
            }),
            modal: '#addModal',
            form: event,
            reset: {
                success: true,
            }
        });
    }

    function updateCategory(event: FormHelper) {
        requestHelper({
            form: event,
            request: FetchApi.patch({
                path: `categories/${category.id}`,
                body: event.value,
            }).then(m => {
                retrieveCategories(query);
                return m;
            }),
            modal: '#editModal'
        });
    }

    const filterChange = (event: FormHelper) => {
        setDebounce(() => {
            setQuery(event.value, 'merge');
        }, 'categories', 800);
    }

    return <>
        <button className="btn btn-primary btn-lg btn-floating" data-bs-toggle="modal" data-bs-target="#addModal">
            <i className="fas fa-plus fa-fw"/>
        </button>
        <div className="d-flex p-2 p-md-4" style={{minHeight: '100vh'}}>
            <div className="my-auto w-100">
                <div className="d-flex">
                    <h2>تصنيفات</h2>
                </div>
                <div className="row">
                    <div className="col-12">
                        <ReactiveForm valueAs="json" onChange={filterChange}>
                            <input type="hidden" name="page" value={1}/>
                            <div className="row">
                                <div className="col-md-4 col-sm-6 col-xl-3 my-3">
                                    <div className="form-floating">
                                        <input type="text" id="q" name="q" className="form-control" placeholder=""
                                               defaultValue={query['q']}/>
                                        <label htmlFor="q">بحث</label>
                                    </div>
                                </div>
                                {/*<div className="col-12 my-3">*/}
                                {/*    <label>*/}
                                {/*        <input type="radio" className="slidebox m-1" name="filter[is_disabled]" value={undefined}/>*/}
                                {/*        الكل*/}
                                {/*    </label>*/}
                                {/*    <label>*/}
                                {/*        <input type="radio" className="slidebox m-1" name="filter[is_disabled]" value={false}/>*/}
                                {/*        المفعلين*/}
                                {/*    </label>*/}
                                {/*    <label>*/}
                                {/*        <input type="radio" className="slidebox m-1" name="filter[is_disabled]" value={true}/>*/}
                                {/*        الغير المفعلين*/}
                                {/*    </label>*/}
                                {/*</div>*/}
                            </div>
                        </ReactiveForm>
                        <hr/>
                    </div>
                    <Loading loading={categories} render={(categories) => {
                        return <>
                            {categories.data.map(category =>
                                <div key={category.name} className="col-md-4 col-sm-6 col-xl-3 my-3">
                                    <div className="card clickable h-100" onClick={() => {
                                        new Modal('#editModal').show();
                                        setCategory(category);
                                    }}>
                                        <div className="card-body d-flex">
                                            {category.name}
                                            <div className="flex-grow-1"/>
                                            <div className={"my-auto"}>
                                                <input type="checkbox" className="slidebox inverted" readOnly={true}
                                                       checked={category.is_disabled}
                                                       onClickCapture={event => {
                                                           event.nativeEvent.stopImmediatePropagation();
                                                           toggleHandler(category);
                                                       }}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <Pagination pagination={categories.meta}/>
                        </>
                    }}/>
                </div>
            </div>
        </div>
        <div className="modal fade" id="editModal" tabIndex={-1}
             aria-hidden="true">
            <ReactiveForm onSubmit={updateCategory} valueAs={"json"} defaultValue={category}
                          className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">تعديل تصنيف</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className="modal-body">
                        <div className="form-floating">
                            <input type="text" className="form-control" defaultValue={category?.name} name="name"
                                   id="name" placeholder=""/>
                            <label htmlFor="name">الاسم</label>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">الغاء</button>
                        <button type="submit" className="btn btn-primary">حفظ</button>
                    </div>
                </div>
            </ReactiveForm>
        </div>
        <div className="modal fade" id="addModal" tabIndex={-1}
             aria-hidden="true">
            <ReactiveForm onSubmit={addCategory} valueAs={"json"} defaultValue={category}
                          className="modal-dialog modal-dialog-centered">
                <input type="hidden" name="parent_id" value={1}/>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">اضافة تصنيف</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className="modal-body">
                        <div className="form-floating">
                            <input type="text" className="form-control" name="name"
                                   id="name" placeholder=""/>
                            <label htmlFor="name">الاسم</label>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">الغاء</button>
                        <button type="submit" className="btn btn-primary">حفظ</button>
                    </div>
                </div>
            </ReactiveForm>
        </div>
    </>
}
