export const environment =
    process.env.NODE_ENV === 'development' ?
        {
            production: false,
            // backend: 'https://lilac-owl.sadeem-lab.com/'
            backend: 'https://lilac-owl.sadeem-factory.com/'
        }
        :
        {
            production: true,
            backend: 'https://lilac-owl.sadeem-factory.com/'
        }
