import * as React from 'react';
import {Route, Switch, useRouteMatch} from 'react-router-dom';
import {ListFatwas} from "./ListFatwas";
import {ShowFatwa} from "./ShowFatwa";
import {CreateFatwa} from "./CreateFatwa";

export const Fatwas = () => {
    let { path, url } = useRouteMatch();
    return (<>
        <Switch>
            <Route path={`${path}`} exact={true}>
                <ListFatwas/>
            </Route>

            <Route path={`${path}/add`} exact={true}>
                <CreateFatwa/>
            </Route>

            <Route path={`${path}/:id`} >
                <ShowFatwa/>
            </Route>

        </Switch>
    </>);
}
