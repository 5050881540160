import * as React from 'react';
import {Loading} from "../helpers/Loading";
import {FetchApi} from "../../FetchApi";
import './home.scss';
import {bigSvg} from "../helpers/bigsvg";

export interface Statistics {
    users: number;
    preachers: number;
    fatwas: number;
}

export const Home = () => {
    const [statistics, setStatistics] = React.useState<Statistics>(null);

    React.useEffect(() => {
        FetchApi.get<Statistics>({
            path: 'statistics',
        }).then(response => {
            setStatistics(response);
        })
    }, []);

    return <>
        <div className="route-container">
            <div className="container">
                <div className="row">
                    <Loading loading={statistics} render={() => <>
                        {/*<div className="my-2 col-md-4">*/}
                        {/*    <div className="card clickable">*/}
                        {/*        <div className="card-body">*/}
                        {/*            <div className="stat" style={{backgroundImage: bigSvg(statistics.users || 0)}}/>*/}
                        {/*            <h3>المستخدمين</h3>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="my-2 col-md-4">
                            <div className="card clickable">
                                <div className="card-body">
                                    <div className="stat" style={{backgroundImage: bigSvg(statistics.preachers)}}/>
                                    <h3>المفتين</h3>
                                </div>
                            </div>
                        </div>
                        <div className="my-2 col-md-4">
                            <div className="card clickable">
                                <div className="card-body">
                                    <div className="stat" style={{backgroundImage: bigSvg(statistics.fatwas || 0)}}/>
                                    <h3>الفتاوي</h3>
                                </div>
                            </div>
                        </div>
                    </>}/>
                </div>
            </div>
        </div>
    </>;
}
