import * as React from 'react';
import {FormEvent} from 'react';


export type FormHelper = {
    form: HTMLFormElement;
    disabled: boolean;
    disable: () => void;
    enable: () => void;
    value: any;
    setValue: any;
    readonly valueAs: 'formdata' | 'json';
};


export const useReactiveInput = (initialValue = undefined) => {
    const [value, setValue] = React.useState(initialValue);
    return {
        onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
            setValue(event.target.value)
        },
        value: value
    };
}

export const ReactiveForm = (
    {
        helperRef,
        children,
        valueAs = 'formdata',
        onChange,
        onSubmit,
        defaultValue,
        ...args
    }: {
        helperRef?: (form: FormHelper) => any;
        onChange?: (form: FormHelper) => any;
        onSubmit?: (form: FormHelper) => any;
        children?: any;
        queryParams?: boolean;
        [key: string]: any;
    } & ({
        defaultValue?: object;
        valueAs: 'json';
    } | {
        defaultValue?: FormData;
        valueAs?: 'formdata';
    })
) => {
    const formRef = React.useRef<HTMLFormElement>();
    const [disabled, setDisabled] = React.useState(false);
    let [value, setValue] = React.useState(defaultValue);


    const disable = () => setDisabled(true);
    const enable = () => setDisabled(false);

    React.useEffect(() => {
        if (helperRef) {
            helperRef({
                form: formRef.current,
                disabled,
                disable,
                enable,
                value,
                setValue,
                valueAs
            })
        }
    }, []);


    React.useEffect(() => {
        if (formRef?.current) {
            formRef.current
                .querySelectorAll<HTMLInputElement | HTMLButtonElement>('[name], button')
                .forEach(element => {
                    if (disabled) {
                        element.setAttribute('disabled', 'disabled');
                    } else {
                        element.removeAttribute('disabled');
                    }
                });
        }
    }, [disabled]);

    const handleValue = (form: HTMLFormElement) => {
        const formData = new FormData(form);

        if (valueAs === 'formdata') {
            return formData;
        } else {
            // @ts-ignore
            const data = Object.fromEntries(formData.entries());
            const booleans = Array.from(form.querySelectorAll<HTMLInputElement>('[type=checkbox]')).map(e => e.name)
            for (const name of booleans) {
                data[name] = ([true, 'true', 'on'].includes(formData.get(name) as string))
            }
            return data;
        }
    };

    const handleFormChange = (event: FormEvent) => {
        value = (handleValue(formRef.current as HTMLFormElement));
        setValue(value);
        onChange?.({
            form: formRef.current,
            disabled,
            disable,
            enable,
            value,
            setValue,
            valueAs
        })
    };

    const handleFormSubmit = (event: FormEvent) => {
        event.preventDefault();
        value = (handleValue(formRef.current as HTMLFormElement));
        setValue(value);
        onSubmit?.({
            form: formRef.current,
            disabled,
            disable,
            enable,
            value,
            setValue,
            valueAs
        })
    };

    return (<>
        <form ref={formRef} onChange={handleFormChange} onSubmit={handleFormSubmit} {...args}>
            {children}
        </form>
    </>);
}
