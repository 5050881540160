import * as React from 'react';
import {FatwaItem} from "./interfaces";
import {setDebounce} from "../helpers/setDebounce";
import {FetchApi} from "../../FetchApi";
import {useParams} from 'react-router-dom';
import {FormHelper, ReactiveForm} from "../helpers/ReactiveForm";
import {PreacherPage} from "../preachers/interfaces";
import {Loading} from "../helpers/Loading";
import {BsEditor} from "../helpers/BsEditor";
import {requestHelper} from "../helpers/requestHelper";
import {decode} from 'html-entities';
import {useCategories} from "../../hooks/useCategories";

export const ShowFatwa = () => {
    const [fatwa, setFatwa] = React.useState<FatwaItem>(null);
    const [preachers, setPreachers] = React.useState<PreacherPage>(null);
    const {categories} = useCategories()

    const retrievePreachers = (body = {}) => {
        setDebounce(() => {
            FetchApi.get<PreacherPage>({
                path: 'preachers',
                body: {sorts: '-created_at', include: 'preacher', ...body},
            }).then(response => {
                setPreachers(response);
            })
        }, 'preachers', 500);
    }

    const {id} = useParams<{ id: string }>();

    const retrieveFatwa = (id: string) => {
        setDebounce(() => {
            FetchApi.get<FatwaItem>({
                path: `fatwas/${id}?include=category`,
            }).then(response => {
                setFatwa(response);
            })
        }, 'fatwas', 500);
    }
    React.useEffect(() => {
        retrievePreachers({paginate: 1000000000000});
        retrieveFatwa(id);
    }, [id]);

    function updateFatwa(event: FormHelper) {
        requestHelper({
            form: event,
            request: FetchApi.patch({
                path: `fatwas/${id}`,
                body: event.value,
            }).then(m => {
                retrieveFatwa(id);
                return m;
            }),
        });
    }


    return <>
        <div className="d-flex p-2 p-md-4" style={{minHeight: '100vh'}}>
            <div className="my-auto w-100">
                <div className="d-flex">
                    <h2>تعديل فتوى</h2>
                </div>
                <Loading loading={fatwa} render={() =>
                    <>
                        <ReactiveForm onSubmit={updateFatwa} valueAs={"json"} className="card my-3">
                            <button className="btn btn-primary btn-lg btn-floating">
                                <i className="fas fa-save fa-fw"/>
                            </button>
                            <div className="card-body">
                                <div className="row g-3">
                                    <div className="col-12">
                                        <div className="form-floating">
                                            <input type="text" className="form-control" name="title" placeholder=""
                                                   defaultValue={fatwa.title}/>
                                            <label>عنوان الفتوى</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <Loading loading={categories} render={() =>
                                            <>
                                                <div className="form-floating">
                                                    <select
                                                        defaultValue={fatwa?.category?.id}
                                                        className="form-control"
                                                        name="category_id">
                                                        {categories.data.map(category => <option key={category.id}
                                                                                                 value={category.id}>{category.name}</option>)}

                                                    </select>
                                                    <label>تصنيف</label>
                                                </div>
                                            </>
                                        }/>
                                    </div>
                                    <div className="col-md-6">
                                        <Loading loading={preachers} render={() =>
                                            <>
                                                <div className="form-floating">
                                                    <select className="form-control" name="preacher_id"
                                                            defaultValue={fatwa.preacher_id}>
                                                        {preachers.data.map(preacher => <option key={preacher.id}
                                                                                                value={preacher.id}>{preacher.name}</option>)}

                                                    </select>
                                                    <label>المفتي</label>
                                                </div>
                                            </>
                                        }/>
                                    </div>
                                </div>
                                <div className="col-12 my-3">
                                    <div className="form-floating">
                                        <BsEditor name="body" defaultValue={decode(fatwa.body)}/>
                                        <label>المحتوى</label>
                                    </div>
                                </div>
                                <div className="col-12 my-3">
                                    <label className={"form-group p-3"}>
                                        <input type="checkbox" defaultChecked={fatwa.is_featured} name="is_featured" className="slidebox"/>
                                        فتوى مميزة
                                    </label>
                                </div>
                            </div>

                        </ReactiveForm>
                    </>
                }/>
            </div>
        </div>
    </>
}
