import * as React from 'react';
import {Loading} from "../helpers/Loading";
import {FetchApi} from "../../FetchApi";
import {requestHelper} from "../helpers/requestHelper";
import {FormHelper, ReactiveForm} from "../helpers/ReactiveForm";
import {Modal} from 'bootstrap';
import {Pagination} from "../helpers/Pagination";
import {useQuery} from "../helpers/useQuery";
import {setDebounce} from "../helpers/setDebounce";
import {PreacherModel, PreacherPage} from "./interfaces";
import {CategoryPage} from "../categories/interfaces";

export const ListPreachers = () => {

    const [preachers, setPreachers] = React.useState<PreacherPage>(null);
    const [preacher, setPreacher] = React.useState<PreacherModel>(null);
    const [query, setQuery] = useQuery();

    const retrievePreachers = (body = {}) => {
        FetchApi.get<PreacherPage>({
            path: 'preachers',
            body: {sorts: '-created_at', ...body},
        }).then(response => {
            setPreachers(response);
        });
    }


    React.useEffect(() => {
        retrievePreachers(query);
    }, [query]);

    const toggleHandler = (preacher: PreacherModel) => {
        requestHelper({
            request: FetchApi.patch({
                path: `preachers/${preacher.id}`,
                // @ts-ignore
                body: {is_disabled: !preacher.is_disabled}
            }).then(response => {
                retrievePreachers(query);
                return response;
            }),
        })
    }


    function addPreacher(event: FormHelper) {
        requestHelper({
            request: FetchApi.post({
                path: `preachers`,
                body: event.value,
            }).then(m => {
                retrievePreachers(query)
                return m;
            }),
            modal: '#addModal',
            form: event,
            reset: {
                success: true,
            }
        });
    }

    function updatePreacher(event: FormHelper) {
        requestHelper({
            form: event,
            request: FetchApi.patch({
                path: `preachers/${preacher.id}`,
                body: event.value,
            }).then(m => {
                retrievePreachers(query);
                return m;
            }),
            modal: '#editModal'
        });
    }

    const filterChange = (event: FormHelper) => {
        setDebounce(() => {
            setQuery(event.value, 'merge');
        }, 'preachers', 800);
    }

    return <>
        <button className="btn btn-primary btn-lg btn-floating" data-bs-toggle="modal" data-bs-target="#addModal">
            <i className="fas fa-plus fa-fw"/>
        </button>
        <div className="d-flex p-2 p-md-4" style={{minHeight: '100vh'}}>
            <div className="my-auto w-100">
                <div className="d-flex">
                    <h2>المفتين</h2>
                </div>
                <div className="row">
                    <div className="col-12">
                        <ReactiveForm valueAs="json" onChange={filterChange}>
                            <input type="hidden" name="page" value={1}/>
                            <div className="row">
                                <div className="col-md-4 col-sm-6 col-xl-3 my-3">
                                    <div className="form-floating">
                                        <input type="text" id="q" name="q" className="form-control" placeholder=""
                                               defaultValue={query['q']}/>
                                        <label htmlFor="q">بحث</label>
                                    </div>
                                </div>
                            </div>
                        </ReactiveForm>
                        <hr/>
                    </div>
                    <Loading loading={preachers} render={(preachers) => {
                        return <>
                            {preachers.data.map(preacher =>
                                <div key={preacher.name} className="col-md-4 col-sm-6 col-xl-3 my-3">
                                    <div className="card clickable h-100" onClick={() => {
                                        new Modal('#editModal').show();
                                        setPreacher(preacher);
                                    }}>
                                        <div className="card-body d-flex">
                                            {preacher.name}
                                            <div className="flex-grow-1"/>
                                            <div className={"my-auto"}>
                                                <input type="checkbox" className="slidebox inverted" readOnly={true}
                                                       checked={preacher.is_disabled}
                                                       onClickCapture={event => {
                                                           event.nativeEvent.stopImmediatePropagation();
                                                           toggleHandler(preacher);
                                                       }}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <Pagination pagination={preachers.meta}/>
                        </>
                    }}/>
                </div>
            </div>
        </div>
        <div className="modal fade" id="editModal" tabIndex={-1}
             aria-hidden="true">
            <ReactiveForm onSubmit={updatePreacher} valueAs={"json"} defaultValue={preacher}
                          className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">تعديل مفتي</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className="modal-body">
                        <div className="form-floating">
                            <input type="text" className="form-control" defaultValue={preacher?.name} name="name"
                                   id="name" placeholder=""/>
                            <label htmlFor="name">الاسم</label>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">الغاء</button>
                        <button type="submit" className="btn btn-primary">حفظ</button>
                    </div>
                </div>
            </ReactiveForm>
        </div>
        <div className="modal fade" id="addModal" tabIndex={-1}
             aria-hidden="true">
            <ReactiveForm onSubmit={addPreacher} valueAs={"json"} defaultValue={preacher}
                          className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">اضافة مفتي</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className="modal-body">
                        <div className="form-floating">
                            <input type="text" className="form-control" name="name"
                                   id="name" placeholder=""/>
                            <label htmlFor="name">الاسم</label>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">الغاء</button>
                        <button type="submit" className="btn btn-primary">حفظ</button>
                    </div>
                </div>
            </ReactiveForm>
        </div>
    </>
}
