import * as React from 'react';
import {ContentState, Editor, EditorState, RichUtils} from "draft-js";
import {convertToHTML, convertFromHTML} from 'draft-convert';

const modifiers: any[][] = [
    [
        {
            type: 'inline',
            command: 'BOLD',
            buttonContent: <i className="fas fa-bold fa-fw"/>,
        },
        {
            type: 'inline',
            command: 'ITALIC',
            buttonContent: <i className="fas fa-italic fa-fw"/>,
        },
        {
            type: 'inline',
            command: 'UNDERLINE',
            buttonContent: <i className="fas fa-underline fa-fw"/>,
        },
    ]
]

export const BsEditor = (
    {
        defaultValue = '',
        value,
        onChange,
        name,
    }:
        {
            name?: string;
            defaultValue?: string;
            value?: string;
            onChange?: (string) => void;
        }
) => {
    const [editorState, setEditorState] = React.useState(() => EditorState.createWithContent(convertFromHTML(defaultValue)));
    const editor = React.useRef<Editor>(null);
    const handleKeyCommand = (command, editorState) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);

        if (newState) {
            setEditorState(newState);
            return 'handled';
        }

        return 'not-handled';
    }

    const handleModifier = (type, command) => {

        let newState = editorState;
        switch (type) {
            case 'inline':
                console.log(type, command);
                newState = RichUtils.toggleInlineStyle(editorState, command)
                break;
        }
        setEditorState(newState);
        setTimeout(() => {
            editor.current.focus();
        })
    }

    React.useEffect(() => {

    }, []);

    return <>
        <div className="form-control h-auto">
            <input type="hidden" name={name} value={convertToHTML(editorState.getCurrentContent())}
                   className={"disable-after-me"}/>
            <Editor
                ref={editor}
                editorState={editorState}
                onChange={setEditorState}
                handleKeyCommand={handleKeyCommand}
            />
        </div>
        <div className="m-1"/>
        {modifiers.map(sub =>
            <div key={JSON.stringify(sub)} className="btn-group btn-group-sm me-1">
                {sub.map(modifier =>
                    <button key={modifier.command} className="btn btn-outline-primary" type="button"
                            onClick={handleModifier.bind(this, modifier.type, modifier.command)}>
                        {modifier.buttonContent}
                    </button>
                )}
            </div>
        )}
    </>
}
