import * as React from "react";
import {SettingModel} from "./interfaces";
import {requestHelper} from "../helpers/requestHelper";
import {FetchApi} from "../../FetchApi";
import {FormHelper} from "../helpers/ReactiveForm";
import {Loading} from "../helpers/Loading";
import {SettingCard} from "./SettingCard";

const filteredSettingsKeys = [
    // "about",
    // "rules",
    // "app_phone",
    // "app_whatsapp_url",
    // "app_facebook_url",
    "app_logo",
    "app_color_primary",
    "app_color_secondary",
    "app_color_on_primary",
    "app_color_on_secondary",
    "app_name",
]

const settingsLabels = {

    about: "نبذة",
    rules: "الشروط",
    app_phone: "رقم الهاتف",
    app_whatsapp_url: "الواتساب",
    app_facebook_url: "رابط الفيسبوك",
    app_email_url: "البريد الالكتروني",
    app_telegram_url: "رابط التيليجرام",
    app_instagram_url: "رابط انستاجرام",
    app_twitter_url: "رابط التويتر",
    app_website_url: "رابط الموقع الالكتروني",
    // "app_logo": "",
    // "app_color_primary": "",
    // "app_color_secondary": "",
    // "app_color_on_primary": "",
    // "app_color_on_secondary": "",
    // "app_name": "",

}

export const Settings = () => {

    const [settings, setSettings] = React.useState<{ data: SettingModel[] }>(null);

    const retrieveSettings = (body = {}) => {
        FetchApi.get<{ data: SettingModel[] }>({
            path: 'settings',
            body: {sorts: 'key', ...body},
        }).then(response => {
            response.data = response.data
                .filter(s => !filteredSettingsKeys.includes(s.key))
                .map(s => {
                    s.key = settingsLabels[s.key] || s.key
                    return s;
                })
            setSettings(response);
        });
    }

    React.useEffect(() => {
        retrieveSettings();
    }, []);


    const updateSetting = async (setting: SettingModel, event: FormHelper) => {
        requestHelper({
            request: FetchApi.patch({
                path: `settings/${setting.id}`,
                // @ts-ignore
                body: event.value,
            }).then(m => {
                return m;
            }),
            form: event,
        });
    }

    return <>
        <div className="d-flex p-2 p-md-4" style={{minHeight: '100vh'}}>
            <div className="my-auto w-100">
                <div className="d-flex">
                    <h2>الضبط</h2>
                </div>
                <div className="row">
                    <Loading loading={settings} render={(settings) => {
                        return <>
                            {settings.data.map(setting =>
                                <SettingCard key={setting.id} setting={setting}
                                             updateSetting={updateSetting}/>
                            )}
                        </>
                    }}/>
                </div>
            </div>
        </div>
    </>
}
