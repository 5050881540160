import * as React from "react";
import { CategoryPage } from "../modules/categories/interfaces";
import { FetchApi } from "../FetchApi";
import { useEffect } from "react";


export const useCategories = () => {
    const [categories, setCategories] = React.useState<CategoryPage>(null)

    const retrieveCategories = (body = {}) => {
        FetchApi.get<CategoryPage>({
            path: 'categories',
            body: { sorts: '-created_at', filters: 'parent_id:1', ...body },
        }).then(response => {
            setCategories(response);
        });
    }

    useEffect(() => {
        retrieveCategories({ paginate: 1000000000000 });
    }, [])


    return { categories }
}
