import * as React from 'react';
import {useQuery} from "./useQuery";
import {useState} from "react";

const getUrlWithQuery = (query: object) => {
    let searchParams = new URLSearchParams(window.location.search);
    for (const key of Object.keys(query)) {
        searchParams.set(key, query[key]);
    }
    return window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
}


type PaginationAttributes = {
    sort_by: string[];
    current_page: number;
    from: number;
    last_page: number;
    path: string;
    per_page: number;
    to: number;
    total: number;
} & object;


export const Pagination = (
    {
        pagination,
        maxSize = 5,
        queryParam = true,
        onPageChange,
        ...args
    }: {
        pagination: PaginationAttributes;
        maxSize?: number;
        queryParam?: boolean | string;
        onPageChange?: (number) => void;
    } & Partial<HTMLDivElement>
) => {

    const queryParamKey = queryParam === true ? 'page' : queryParam === false ? '' : queryParam;
    const [pages, setPages] = React.useState([]);
    const [page, setPage] = React.useState(pagination.current_page);
    const [query, setQuery] = useQuery();
    const [random] = useState(() => Math.random().toString());

    const updatePages = () => {
        let length = Math.min(Math.ceil(pagination.total / pagination.per_page))
        let base = 1;
        if (length > maxSize) {
            base = Math.max(1, Math.min(Math.round(+page - (+maxSize * 0.5)), length - +maxSize + 1));
            length = +maxSize;
        }
        setPages(new Array(Math.ceil(length)).fill(base).map((a, i) => i + a));
    }

    React.useEffect(() => {
        setPage(pagination.current_page);
        updatePages();
    }, [pagination]);

    React.useEffect(() => {
        if (query[queryParamKey]) {
            setPage(query[queryParamKey]);
        }
    }, [query]);


    React.useEffect(() => {
        updatePages();
    }, [page, pagination]);

    const getPaginationLinkHook = (page) => {
        return {
            href: getUrlWithQuery({page}),
            onClick: event => {
                event.preventDefault();
                setQuery({page}, 'merge');
                setPage(page);
            }
        };
    }

    return <>
        {/* @ts-ignore */}
        <div {...args}>
            <ul className="pagination pagination-sm">
                <li className="page-item"><a
                    className="page-link" {...getPaginationLinkHook(1)}>الأولى</a>
                </li>
                <li className="page-item"><a
                    className="page-link" {...getPaginationLinkHook(Math.max(1, page - 1))}>السابق</a>
                </li>
                {pages.map(p =>
                    <li key={random + p} className={`page-item ${page === p ? 'active' : ''}`}><a key={p}
                                                                                                  className="page-link" {...getPaginationLinkHook(p)}>{p}</a>
                    </li>
                )}
                <li className="page-item"><a
                    className="page-link" {...getPaginationLinkHook(Math.min(pagination.last_page, page + 1))}>التالي</a>
                </li>
                <li className="page-item"><a
                    className="page-link" {...getPaginationLinkHook(pagination.last_page)}>الأخيرة</a>
                </li>
            </ul>
        </div>
    </>
}
