import * as React from 'react';
import { Redirect } from "react-router-dom";

export const Logout = () => {
  React.useLayoutEffect(() => {
    localStorage.removeItem('token');
    window.location.href = "/login";
  }, []);

  return <Redirect to="/login" />
}
