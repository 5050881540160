import {FormHelper} from "./ReactiveForm";
import {toast} from "react-toastify";
import {Modal} from 'bootstrap';


export type RequestInterface<T = any> = {
    request: Promise<T | any>;
    form?: FormHelper;
    modal?: string;
    redirectTo?: string | {
        success?: string;
        error?: string;
    };
    reset?: boolean | {
        success?: boolean;
        error?: boolean;
    }
};

export const requestHelper = async <T extends unknown>(request: RequestInterface<T>) => {


    if (request.form) {
        request.form.disable();
        request.form.form.querySelectorAll(`.is-invalid`).forEach(element => element.classList.remove('is-invalid'));
        request.form.form.querySelectorAll(`.invalid-feedback`).forEach(element => element.remove());
    }

    let successRedirect = null;
    let errorRedirect = null;
    if (![undefined, null].includes(request.redirectTo)) {
        if (typeof request.redirectTo === 'string') {
            successRedirect = errorRedirect = request.redirectTo;
        } else {
            if (![undefined, null].includes(request.redirectTo.success)) {
                successRedirect = request.redirectTo.success;
            }

            if (![undefined, null].includes(request.redirectTo.error)) {
                errorRedirect = request.redirectTo.error;
            }
        }
    }

    let successReset = null;
    let errorReset = null;
    if (![undefined, null].includes(request.reset)) {
        if (typeof request.reset === 'boolean') {
            successReset = errorReset = request.reset;
        } else {
            if (![undefined, null].includes(request.reset.success)) {
                successReset = request.reset.success;
            }

            if (![undefined, null].includes(request.reset.error)) {
                errorReset = request.reset.error;
            }
        }
    }


    try {
        const response = await request.request

        if (response.msg || response.message) {
            toast(response.msg || response.message, {
                className: 'bg-success text-white',
                autoClose: 3000,
            })
        } else {
            toast('تمت العملية بنجاح', {
                className: 'bg-success text-white',
                autoClose: 3000,
            })
        }

        if (successRedirect) {
            window.location.assign(successRedirect);
        }

        if (successReset && request.form) {
            request.form.form.reset();
        }

        if (request.modal) {
            Modal.getInstance(document.querySelector(request.modal)).hide();
        }
    } catch (e) {
        if (e.msg || e.message) {
            toast(e.msg || e.message, {
                className: 'bg-danger text-white',
                autoClose: 3000,
            });
        }
        if (e.code === 3024 && request.form) {
            const errors = e.errors as { [key: string]: string[] };
            for (const key of Object.keys(errors)) {
                const element = request.form.form.querySelector(`[name^=${key}]`);
                if (element) {
                    element.classList.add('is-invalid');
                    const invalidElement = document.createElement('div');
                    invalidElement.className = 'invalid-feedback';
                    invalidElement.innerHTML = errors[key].join(', ');
                    if (element.nextSibling) {
                        element.parentElement.insertBefore(invalidElement, element.nextSibling);
                    } else {
                        element.parentElement.append(invalidElement);
                    }
                }
            }
        }

        if (errorRedirect) {
            window.location.assign(errorRedirect);
        }


        if (errorReset && request.form) {
            request.form.form.reset();
        }

        throw e;
    } finally {
        if (request.form) {
            request.form.enable();
        }
    }
}
